export class ErrorEntity extends Error {
    private readonly _title: string;
    private readonly _message: string;
    private readonly _date: Date;

    constructor (title: string, message: string) {
        super();
        this._title = title;
        this._message = message;
        this._date = new Date();
    }

    get title (): string {
        return this._title;
    }

    get message (): string {
        return this._message;
    }

    get date (): Date {
        return this._date;
    }
}
