import { ErrorEntity } from '@/errors/errorEntity';

export function useErrorHandling() {
    const { addToast } = useToasts();

    function handleError(error: unknown) {
        if (error instanceof ErrorEntity)
            addToast({
                title: error.title,
                message: error.message,
                type: 'error'
            });
    }

    return {
        handleError
    };
}
