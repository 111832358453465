<template>
  <div class="relative isolate bg-[#0b0509] min-h-screen">
    <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
      <div class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
        <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
          <div class="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
            <div class="absolute inset-0 h-full w-full">
              <img class="object-cover h-full w-full" src="/assets/yachting.webp"/>
            </div>
          </div>
        </div>
      </div>
      <form action="#" method="POST" class="px-6 pb-24 pt-20 sm:pb-12 lg:px-8 lg:pt-12 mt-6" @submit.prevent="submit">
        <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
          <h2 class="text-3xl font-bold tracking-tight text-white">Contact us</h2>
          <p class="my-6 text-base leading-8 text-gray-300">Discretion is of utmost importance to our clients. Please reach out to learn more about our business and offerings.</p>
          <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label for="firstname" class="block text-sm font-semibold leading-6 text-white">First name</label>
                <input type="text" v-model="submission.firstname" name="firstname" id="firstname" autocomplete="given-name" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" required />
            </div>
            <div>
              <label for="lastname" class="block text-sm font-semibold leading-6 text-white">Last name</label>
                <input type="text" v-model="submission.lastname" name="lastname" id="lastname" autocomplete="family-name" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" required />
            </div>
            <div class="sm:col-span-2">
              <label for="company" class="block text-sm font-semibold leading-6 text-white">Company</label>
                <input type="text" v-model="submission.company" name="company" id="company" autocomplete="email" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" required />
            </div>
            <div class="sm:col-span-2">
              <label for="email" class="block text-sm font-semibold leading-6 text-white">Email</label>
                <input type="email" v-model="submission.email" name="email" id="email" autocomplete="email" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" required />
            </div>
            <div class="sm:col-span-2">
              <div class="flex justify-between text-sm leading-6">
                <label for="phone" class="block text-sm font-semibold leading-6 text-white">Phone number</label>
                <p id="message-description" class="text-gray-400">Optional</p>
              </div>
              <input type="tel" name="phone" v-model="submission.phone" id="phone" autocomplete="tel" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" />
            </div>
            <div class="sm:col-span-2">
              <div class="flex justify-between text-sm leading-6">
                <label for="message" class="block text-sm font-semibold leading-6 text-white">Message</label>
                <p id="message-description" class="text-gray-400">Optional</p>
              </div>
              <textarea name="message" v-model="submission.message" id="message" rows="4" class="focus-visible:outline-none block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#2f201b] sm:text-sm sm:leading-6" />
            </div>
            <div v-if="isLoading" class="loader">
              <div role="status">
                <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#2f201b]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else class="flex items-center col-span-2">
              <div class="flex flex-col w-full">
                <label for="captcha" class="block text-sm font-semibold leading-6 text-white">Captcha</label>
                <div class="flex items-center mt-2.5">
                  <div class="w-40 flex items-center bg-gray-300 border border-gray-600 rounded">
                    <span v-if="captcha" v-html="captcha.svg" />
                  </div>
                  <button value="Refresh" @click="getCaptcha" class="ml-6 block rounded-md bg-[#2f201b] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#4f3f3a] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 cursor-pointer"><ArrowPathIcon class="w-5" /></button>
                  <input id="captcha" type="text" class="focus-visible:outline-none block w-1/3 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 ml-auto" maxlength="4" @keydown.enter="submit" v-model="submission.captcha" required />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 flex justify-end">
            <button type="submit" v-if="!isSending" class="rounded-md bg-[#2f201b] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#4f3f3a] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2f201b]">Send message</button>
            <button type="button" v-else class="flex items-center justify-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#2f201b] hover:bg-[#4f3f3a] transition ease-in-out duration-150 cursor-not-allowed focus-visible:outline-[#2f201b]" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading...
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { ErrorEntity } from "~/errors";

const captcha = ref(null)
const isLoading = ref(true)
const isSending = ref(false)
const submission = ref({
  firstname: '',
  lastname: '',
  company: '',
  email: '',
  phone: '',
  message: '',
  captcha: '',
  uuidCaptcha: '',
})

async function getCaptcha() {
  isLoading.value = true
  const response = await fetch('/api/generate')
  if (response.ok) {
    const data = await response.json()
    captcha.value = data
    submission.value.uuidCaptcha = data.uuid
  }
  isLoading.value = false
}

async function submit(event: { preventDefault: () => void; }) {
  isSending.value = true
  event.preventDefault()
  const { addToast } = useToasts();
  const { handleError } = useErrorHandling();

  await useFetch('/api/mail', {
    retry: false,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...submission.value, objectMail: 'YACHTING - Nouvelle demande de contact' }),
    onResponseError({ request, response, options, error }) {
      handleError(new ErrorEntity('Email not send', 'Error ' + response._data.statusCode));
      isSending.value = false;
    },
    onResponse({ request, response, options }) {
      if (response.status === 200) {
        addToast({
          title: 'Success',
          message: 'Your message has been sent successfully.',
          type: 'success',
        });
        submission.value = {
          firstname: '',
          lastname: '',
          company: '',
          email: '',
          phone: '',
          message: '',
          captcha: '',
          uuidCaptcha: '',
        };
        isSending.value = false;
      }
    }
  });
  await getCaptcha();
  isSending.value = false;
}

onMounted(getCaptcha)

</script>
